import { Component, HostListener } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
  RouterOutlet,
} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem } from '../../models/menu-item';
import { MENUITEMS } from '../../app.routes';
import { DomSanitizer } from '@angular/platform-browser';
import { MatRippleModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from '../../services/auth.service';
import { ProfileMenuComponent } from '../../shared/components/profile-menu/profile-menu.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { LocalStorageEnum } from '../../models/enums/local-storage-enum';
import { UserRoleEnum } from '../../models/enums/user-role-enum';

@Component({
  selector: 'app-container',
  standalone: true,
  imports: [
    MatSidenavModule,
    RouterOutlet,
    MatListModule,
    MatToolbarModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    MatExpansionModule,
    MatRippleModule,
    MatMenuModule,
    ProfileMenuComponent,
  ],
  templateUrl: './container.component.html',
  styleUrl: './container.component.scss',
})
export class ContainerComponent {
  userRoleEnum = UserRoleEnum;
  opened: boolean = true;
  desktopViewWidth: number = 1100;
  drawerMode: 'over' | 'side' = 'side';
  pageTitle: string = '';
  pageType: string = '';
  mongoIDReg = /^[a-fA-F0-9]{24}$/;

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    if (width < this.desktopViewWidth) {
      this.drawerMode = 'over';
      if (this.opened) {
        this.opened = false;
      }
    } else {
      this.drawerMode = 'side';
      if (!this.opened) {
        this.opened = true;
      }
    }
  }

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    router: Router,
    private _route: ActivatedRoute,
    private _localStorageService: LocalStorageService
  ) {
    iconRegistry.addSvgIcon(
      'dashboard',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg-icons/home.svg')
    );
    iconRegistry.addSvgIcon(
      'settings',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg-icons/settings.svg')
    );

    iconRegistry.addSvgIcon(
      'request',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/svg-icons/request.svg')
    );

    iconRegistry.addSvgIcon(
      'report',
      sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/svg-icons/pie-chart.svg'
      )
    );

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const replaceTitle = event.urlAfterRedirects.replace('/', '');
        const hasQuery = event.urlAfterRedirects.includes('?');
        if (hasQuery) {
          this.pageTitle = replaceTitle.split('?')[0];
        } else {
          this.pageTitle = replaceTitle;
        }
        this.pageType = this._route.firstChild.snapshot.data['type'];
        const splittedTitles = this.pageTitle.split('/');
        const lastPart = splittedTitles[splittedTitles.length - 1];

        if (
          this.mongoIDReg.test(lastPart) &&
          splittedTitles[splittedTitles.length - 2] === 'request-management'
        ) {
          this.pageTitle = 'request_detail';
        }
        if (lastPart === 'report') {
          this.pageTitle = 'report';
          this.pageType = '';
        }
        if (
          this.mongoIDReg.test(lastPart) &&
          splittedTitles[splittedTitles.length - 2] === 'departments'
        ) {
          this.pageTitle = 'department_detail';
          this.pageType = '';
        }
        if (
          this.mongoIDReg.test(lastPart) &&
          splittedTitles[splittedTitles.length - 2] === 'air-company'
        ) {
          this.pageTitle = 'air_company_detail';
          this.pageType = '';
        }
        if (
          this.mongoIDReg.test(lastPart) &&
          splittedTitles[splittedTitles.length - 2] === 'division-wallets'
        ) {
          this.pageTitle = 'division_wallet_page.info';
          this.pageType = '';
        }
        if (splittedTitles[splittedTitles.length - 2] === 'division-wallets') {
          if (lastPart === 'new' || lastPart.split('?')[0] === 'new')
            this.pageTitle = 'division_wallet_page.add';
          this.pageType = '';
        }

        if (
          lastPart === 'edit' &&
          splittedTitles[splittedTitles.length - 3] === 'division-wallets'
        ) {
          this.pageTitle = 'division_wallet_page.update';
          this.pageType = '';
        }

        if (this.drawerMode === 'over' && this.opened) {
          this.opened = false;
        }
      }
    });
  }

  dashboardMenu = MENUITEMS.filter((item: MenuItem) => {
    return item.type === 'dashboard';
  });

  requestManagementMenu = MENUITEMS.filter((item: MenuItem) => {
    return item.type === 'request-management';
  });

  reportMenu = MENUITEMS.filter((item: MenuItem) => {
    return item.type === 'report';
  });

  settingsMenu = MENUITEMS.filter((item: MenuItem) => {
    return item.type === 'settings';
  });

  menu = {
    dashboard: this.dashboardMenu,
    report: this.reportMenu,
    requestManagement: this.requestManagementMenu,
    settings: this.settingsMenu,
  };

  toggleMenu() {
    this.opened = !this.opened;
  }

  username =
    this._localStorageService.get(LocalStorageEnum.user_firstname) +
    ' ' +
    this._localStorageService.get(LocalStorageEnum.user_lastname);

  userRole = this._localStorageService.get(LocalStorageEnum.user_role);
}
