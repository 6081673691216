import { Routes } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { MenuItem } from './models/menu-item';
import { authGuard } from './guards/auth.guard';
import { RequesterContainerComponent } from './components/requester-container/requester-container.component';
import { requesterAuthGuard } from './guards/requester-auth.guard';
import { notLoginGuard } from './guards/not-login.guard';

export const routes: Routes = [
  {
    path: '',
    component: ContainerComponent,
    children: [
      // {
      //   path: 'dashboard',
      //   data: {
      //     type: 'dashboard',
      //     icon: 'dashboard',
      //   },
      //   canActivate: [authGuard],
      //   loadChildren: () =>
      //     import('./routes/dashboard/dashboard.module').then(
      //       (m) => m.DashboardModule
      //     ),
      // },
      {
        path: 'countries',
        canActivate: [authGuard],
        data: {
          type: 'settings',
          icon: 'country',
        },
        loadChildren: () =>
          import('./routes/countries/countries.module').then(
            (m) => m.CountriesModule
          ),
      },
      {
        path: 'country-groups',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/country-groups/country-groups.module').then(
            (m) => m.CountryGroupsModule
          ),
      },
      {
        path: 'positions',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/positions/positions.module').then(
            (m) => m.PositionsModule
          ),
      },
      {
        path: 'ranks',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/ranks/ranks.module').then((m) => m.RanksModule),
      },
      // {
      //   path: 'titles',
      //   data: {
      //     type: 'settings',
      //   },
      //   canActivate: [authGuard],
      //   loadChildren: () =>
      //     import('./routes/titles/titles.module').then((m) => m.TitlesModule),
      // },
      {
        path: 'divisions',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/divisions/divisions.module').then(
            (m) => m.DivisionsModule
          ),
      },
      {
        path: 'departments',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/departments/departments.module').then(
            (m) => m.DepartmentsModule
          ),
      },
      {
        path: 'officers',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/officers/officers.module').then(
            (m) => m.OfficersModule
          ),
      },
      {
        path: 'mission-budgets',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/mission-budgets/mission-budgets.module').then(
            (m) => m.MissionBudgetsModule
          ),
      },
      {
        path: 'air-fees',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/airfees/airfees.module').then(
            (m) => m.AirfeesModule
          ),
      },
      {
        path: 'request-management',
        data: {
          type: 'request-management',
          icon: 'request',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/request-management/request-management.module').then(
            (m) => m.RequestManagementModule
          ),
      },
      {
        path: 'report',
        data: {
          type: 'report',
          icon: 'report',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'division-wallets',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/division-wallets/division-wallets.module').then(
            (m) => m.DivisionWalletsModule
          ),
      },
      {
        path: 'wallets',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/wallets/wallets.module').then(
            (m) => m.WalletsModule
          ),
      },
      {
        path: 'users',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'air-company',
        data: {
          type: 'settings',
        },
        canActivate: [authGuard],
        loadChildren: () =>
          import('./routes/air-company/air-company.module').then((m) => m.AirCompanyModule),
      },
      {
        path: '',
        redirectTo: 'request-management',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'requests',
    component: RequesterContainerComponent,
    children: [
      {
        path: '',
        canActivate: [requesterAuthGuard],
        loadChildren: () =>
          import('./routes/requests/requests.module').then(
            (m) => m.RequestsModule
          ),
      },
    ],
  },
  {
    path: 'login',
    canActivate: [notLoginGuard],
    loadChildren: () =>
      import('./routes/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: 'request-management',
    pathMatch: 'full',
  },
];

export const MENUITEMS: Array<MenuItem> = routes[0].children!.map(
  (routeItem) => {
    return {
      route: routeItem.path,
      type: routeItem.data?.type,
      roles: routeItem.data?.roles,
      title: routeItem.path,
      icon: routeItem.data?.icon,
    } as MenuItem;
  }
);
